import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';

import { RichText } from 'prismic-reactjs';

import useLangRedirect from '../hooks/useLangRedirect';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Row from '../components/Row';
import Col from '../components/Col';
import SliceZone from '../components/SliceZone';
import HeroCarousel from '../components/HeroCarousel';
import ResponsiveEmbed from '../components/ResponsiveEmbed';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidthText};
  margin: ${rem(50)} auto 0;
  padding-bottom: ${rem(80)};
`;

function Project({ data, pageContext }) {
  useLangRedirect(pageContext);

  const { project: page } = data.prismic;

  if (!page) {
    return null;
  }

  return (
    <Layout>
      <SEO
        title={RichText.asText(page.seo_meta_title || page.title)}
        description={page.seo_description}
      />

      {page.hero_items && page.hero_items.length ? (
        <HeroCarousel items={page.hero_items} />
      ) : (
        <Hero
          preHeading="Referensprojekt"
          heading={RichText.asText(page.title)}
          image={page.featured_imageSharp}
          pageTitle
          big
        >
          {page.hero_text && <RichText render={page.hero_text} />}{' '}
        </Hero>
      )}

      <Container>
        <Row>
          {page.featured_video && (
            <Col>
              <ResponsiveEmbed>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: page.featured_video?.html,
                  }}
                />
              </ResponsiveEmbed>
            </Col>
          )}
          <Col>
            <SliceZone slices={page.body} />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

Project.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const query = graphql`
  query($uid: String!, $lang: String!) {
    prismic {
      project(uid: $uid, lang: $lang) {
        body {
          ... on PRISMIC_ProjectBodyQuote {
            type
            label
            primary {
              quote
            }
          }
          ... on PRISMIC_ProjectBodyText {
            type
            label
            primary {
              text
            }
          }
          ... on PRISMIC_ProjectBodyImage {
            type
            label
            primary {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 75) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        featured_image
        featured_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        featured_video
        seo_meta_title
        seo_description
        hero_text
        hero_title
        hero_image
        hero_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        _meta {
          uid
          lang
          type
        }
      }
    }
  }
`;

export default Project;
